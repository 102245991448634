<template>
  <div class="row">
    <div class="col-md-12 mt-2">
      <div class="card">
        <h2 class="text-center mt-2">Revenue Share</h2>
        <b-row v-if="isError" class="justify-content-center mt-2">
          <b-col lg="10">
            <b-alert variant="danger" show dismissible>
              Opps .. Something is wrong
            </b-alert>
          </b-col>
        </b-row>
        <div class="card-body">
          <div class="form-group row justify-content-center">
            <div class="col-sm-2">
              <b-form-select
                id="currency"
                class="mb-2"
                v-model="status"
                :options="statusOptions"
                required
                @change="onStatusChange"
              ></b-form-select>
            </div>
            <div class="col-sm-6">
              <input
                type="text"
                class="form-control"
                placeholder="type name of revenue share..."
                @input="debounceSearch"
              />
              <span v-if="typing"> <em> searching... </em> </span>
            </div>
            <div class="col-sm-1">
              <router-link
                class="btn btn-block btn-primary"
                :to="{ path: '/revenueshare/create' }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </div>
          </div>
          <div class="row justify-content-end">
            <label for="" class="col-sm-auto">Total : </label>
            <strong class="col-sm-auto">{{ formatNumber(totalRows) }}</strong>
          </div>
          <b-table
            sticky-header="500px"
            :items="RevenueShare"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            show-empty
            responsive="xl"
            hover
            sort-icon-left
          >
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}.
            </template>

            <template #cell(fixed_amount)="row">
              {{ parseFloat(row.item.default_fixed_amount).toFixed(1) }}
            </template>

            <template #cell(percentage_amount)="row">
              {{ toPercent(row.item.default_percentage_amount) }}
              %
            </template>

            <template #cell(created)="row">
              <div style="width: 250px">
                {{ row.item.created ? formatDate(row.item.created) : '-' }}
              </div>
            </template>
            <template v-slot:cell(is_active)="row">
              <div>
                <span v-if="row.item.is_active" class="badge badge-success">
                  Active
                </span>
                <span v-else class="badge badge-danger">
                  In Active
                </span>
              </div>
            </template>

            <template #cell(action)="row">
              <div class="btn-group" role="group" aria-label="Basic example">
                <router-link
                  :to="{ path: '/revenueshare/edit/' + row.item.id }"
                  class="link btn btn-info"
                >
                  <i class="fa fa-pencil"></i>
                </router-link>
              </div>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'RevenueShare',
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      message: '',
      typing: null,
      fields: [
        { key: 'No', label: 'No.' },
        { key: 'name', label: 'Name' },
        { key: 'description', label: 'Description' },
        { key: 'fixed_amount', label: 'Fixed Amount' },
        { key: 'percentage_amount', label: 'Percentage Amount' },
        { key: 'is_active', label: 'Status' },
        { key: 'created', label: 'Created' },
        { key: 'action', label: '' },
      ],
      status: '',
      statusOptions: [
        { value: '', text: 'All' },
        { value: 'true', text: 'Active' },
        { value: 'false', text: 'In Active' },
      ],
    };
  },
  watch: {
    currentPage: function() {
      this.getRevenueShares();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Revenue Share | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.revenueshares.isLoading,
      isError: (state) => state.revenueshares.isError,
      totalRows: (state) => state.revenueshares.totalRows,
      RevenueShare: (state) => state.revenueshares.items,
    }),
  },
  mounted() {
    this.getRevenueShares();
  },
  methods: {
    ...mapActions('revenueshares', ['fetchAllRevenueShares']),

    getRevenueShares() {
      let payload = {
        name: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchAllRevenueShares(payload);
    },

    debounceSearch(event) {
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        this.fetchAllRevenueShares({
          name: this.message,
          page: 1,
          limit: this.perPage,
          status: this.status,
        });
      }, 600);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },

    toPercent(num) {
      return (parseFloat(num) * 100).toFixed(1);
    },

    onStatusChange() {
      let payload = {
        name: this.message,
        status: this.status,
        page: this.currentPage,
        limit: this.perPage,
      };
      this.fetchAllRevenueShares(payload);
    },
  },
};
</script>
